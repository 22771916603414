import { RootState } from '@/store/types';
import {
  UsersApi,
  RecipesApi,
  KeepApi,
  ReweApi,
  SessionsApi,
  SplitwiseApi,
} from '@yurop-alfred/alfred-api';

interface AlfredApi {
  accessToken: string;
  users: UsersApi;
  recipes: RecipesApi;
  keep: KeepApi;
  rewe: ReweApi;
  sessions: SessionsApi;
  splitwise: SplitwiseApi;
}

let currentApi: AlfredApi | undefined = undefined;

export const api = (rootState: RootState): AlfredApi => {
  const accessToken = rootState.auth.token || '';
  if (currentApi && currentApi.accessToken === accessToken) {
    return currentApi;
  }
  const opts = { accessToken, basePath: config.API_URL };
  currentApi = {
    accessToken,
    users: new UsersApi(opts),
    recipes: new RecipesApi(opts),
    keep: new KeepApi(opts),
    rewe: new ReweApi(opts),
    sessions: new SessionsApi(opts),
    splitwise: new SplitwiseApi(opts),
  };
  return currentApi;
};
