import { Module } from 'vuex';
import { AuthState, RootState } from './types';
import { User, UsersApi } from '@yurop-alfred/alfred-api';

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    authenticated: false,
    // TODO replace localStorage with something more secure?
    token: localStorage.getItem('token') || undefined,
    user: undefined,
    loginError: undefined,
  },
  actions: {
    loginWithToken({ commit }, token: string) {
      commit('setToken', token);
      new UsersApi({ accessToken: token, basePath: config.API_URL })
        .usersMeGet()
        .then(
          (response) => {
            const user = response.data;
            if (user) {
              commit('successfulLogin', user);
            } else {
              commit(
                'failedLogin',
                'Nutzerdaten konnten nicht abgerufen werden.'
              );
            }
          },
          (error) => {
            if (error.response && error.response.status === 401) {
              commit('failedLogin', 'Dieser Loginlink ist bereits abgelaufen!');
            } else {
              // Unknown error
              // TODO log
              commit('failedLogin', error.message);
            }
          }
        );
    },
    logout({ commit }) {
      // delete legacy cookie
      document.cookie = 'hash= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      commit('logout', '');
    },
  },
  mutations: {
    setToken(state, payload: string) {
      state.token = payload;
      localStorage.setItem('token', state.token);
    },
    successfulLogin(state, payload: User) {
      state.loginError = undefined;
      state.user = payload;
      state.authenticated = true;
    },
    failedLogin(state, error: string) {
      state.loginError = error;
      state.user = undefined;
      state.authenticated = false;
    },
    logout(state) {
      localStorage.removeItem('token');
      state.token = undefined;
      state.user = undefined;
      state.authenticated = false;
      state.loginError = undefined;
    },
  },
};
