























































































































































































import { Component, Vue } from 'vue-property-decorator';
import MoneySumTextField from '@/components/MoneySumTextField.vue';
import { UsersApi, Session, EbonEntry } from '@yurop-alfred/alfred-api';
import { State, namespace } from 'vuex-class';
import { SplitwiseState } from '../store/types';
import draggable from 'vuedraggable';

const splitwiseNs = namespace('splitwise');

@Component({
  components: {
    MoneySumTextField,
    draggable,
  },
})
export default class Splitwise extends Vue {
  @State('splitwise')
  splitwiseState!: SplitwiseState;

  @splitwiseNs.Action('fetchSessions')
  fetchSessions: any;
  @splitwiseNs.Action('selectSessions')
  selectSessions: any;
  @splitwiseNs.Action('insertPurchases')
  insertPurchases: any;

  selectedSessions: Session[] = [];
  mounted() {
    // TODO only fetch the last 10 completed sessions
    this.fetchSessions();
    //new UsersApi().usersUserIdGet().then(user => user.data.id);
  }

  chooseSessions() {
    this.selectSessions(this.selectedSessions);
  }

  calculateSum(items: any, additional: number) {
    let sum: number = additional;
    for (const item of items) {
      sum += item.sub_total;
    }
    return sum;
  }

  insertIntoSplitwise() {
    if (confirm('Willst du den Einkauf so in Splitwise eintragen?')) {
      this.insertPurchases();
    }
  }
}
