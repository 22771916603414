


















































import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import { DefaultsState } from '../store/defaults';

const defaultsNs = namespace('defaults');

@Component({})
export default class Defaults extends Vue {
  @State('defaults')
  state!: DefaultsState;

  @defaultsNs.Action('fetchDefaults')
  fetchDefaults: any;
  @defaultsNs.Action('changeDefault')
  changeDefault: any;

  editingDefault: number = -1;

  mounted() {
    this.fetchDefaults();
  }

  editDefault(index: number, target: any) {
    this.editingDefault = index;

    // move dialog
    var dialog = document.getElementById('defaultsDialog')!;
    var bodyRect = document.body.getBoundingClientRect(),
      rect = target.getBoundingClientRect();

    dialog.style.top = rect.top - bodyRect.top + 20 + 'px';

    var leftOffset =
      rect.left - dialog.offsetWidth / 2 + target.offsetWidth / 2;
    if (leftOffset + dialog.offsetWidth >= document.body.clientWidth) {
      dialog.style.right = '0px';
      dialog.style.left = '';
    } else {
      dialog.style.left = leftOffset + 'px';
      dialog.style.right = '';
    }
  }

  setDefault(participating: number) {
    this.changeDefault({ weekday: this.editingDefault, participating });
    this.editingDefault = -1;
  }
}
