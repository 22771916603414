








import Vue from 'vue';
export default Vue.extend({});
// TODO make size a prop
