































import { Component, Vue } from 'vue-property-decorator';
import {
  RecipesApiFactory,
  Recipe,
  RecipesApi,
} from '@yurop-alfred/alfred-api';
import { State, Action, namespace } from 'vuex-class';
import { RecipeListState } from '../store/types';

const recipeList = namespace('recipeList');

@Component({})
export default class RecipeList extends Vue {
  @State((state) => state.auth.user && state.auth.user.vip)
  isVip?: boolean;
  @State('recipeList')
  recipeList!: RecipeListState;
  @recipeList.Action('fetchRecipes')
  fetchRecipes: any;

  searchString: string = '';
  mounted() {
    this.fetchRecipes();
  }

  get filteredRecipeList() {
    if (this.recipeList.recipes === undefined) {
      return [];
    }
    const recipes = this.recipeList.recipes.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    if (this.searchString === '') {
      return recipes;
    }
    return recipes.filter(
      (recipe) =>
        recipe.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
        -1
    );
  }
}
