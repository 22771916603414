import { EbonState, RootState } from './types';
import { Module } from 'vuex';
import { api } from '@/api';
import { Ebon } from '@yurop-alfred/alfred-api';

export const ebon: Module<EbonState, RootState> = {
  namespaced: true,
  state: {
    error: undefined,
    loading: false,
    ebons: [],
  },
  actions: {
    fetchEbons({ commit, rootState }) {
      commit('startLoading');
      api(rootState)
        .rewe.reweGet()
        .then(
          (response) => commit('gotEbons', response.data),
          (error) => commit('onError', error)
        );
    },
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    gotEbons(state, payload: Ebon[]) {
      state.ebons = payload;
      state.error = undefined;
      state.loading = false;
    },
    onError(state, error: string) {
      state.loading = false;
      state.error = error;
      state.ebons = [];
    },
  },
};
