




















import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace, State } from 'vuex-class';
import { NomonomiconState } from '../store/types';
const nomNamespace = namespace('nomonomicon');

@Component({})
export default class SuggestRecipe extends Vue {
  @State('nomonomicon')
  state!: NomonomiconState;
  @nomNamespace.Action('fetchSuggestion')
  fetchSuggestion: any;
  mounted() {
    if (this.$route.query.participants) {
      const participants = (this.$route.query.participants as string)
        .split(',')
        .map((a) => parseInt(a));
      this.fetchSuggestion(participants);
    }
  }
}
