





















import { Component, Vue } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import { AuthState } from '../store/types';
import { auth } from '../store/auth';
const authNs = namespace('auth');

@Component({})
export default class User extends Vue {
  @State('auth') authState!: AuthState;
  @authNs.Action('logout') logout: any;
  showEbonsLink = config.USE_REWE;

  mounted() {}

  doLogout() {
    if (confirm('Willst du dich wirklich ausloggen?')) {
      this.logout();
    }
  }
}
