



















import { Component, Vue } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import { EbonState } from '../store/types';
const ebonNs = namespace('ebon');
const splitwiseNs = namespace('splitwise');
@Component({})
export default class EbonsList extends Vue {
  @State('ebon')
  ebonState!: EbonState;
  showSplitwiseLink = config.USE_SPLITWISE;

  @ebonNs.Action('fetchEbons')
  fetchEbons: any;
  @splitwiseNs.Action('selectReceipts')
  selectReceipts: any;

  mounted() {
    this.fetchEbons();
  }

  enterInSplitwise(id: number) {
    this.selectReceipts([id]);
    this.$router.push({
      name: 'splitwise',
    });
  }
}
