import { Module } from 'vuex';
import { RecipeListState, RootState } from './types';
import {
  User,
  UsersApi,
  Recipe,
  RecipesApiFp,
  RecipesApi,
} from '@yurop-alfred/alfred-api';
import { api } from '@/api';

export const recipeList: Module<RecipeListState, RootState> = {
  namespaced: true,
  state: {
    error: undefined,
    loadingRecipes: false,
    recipes: [],
  },
  actions: {
    fetchRecipes({ commit, rootState }) {
      commit('startLoading');
      api(rootState)
        .recipes.recipesGet()
        .then(
          (response) => commit('gotRecipes', response.data),
          (error) => commit('onError', error)
        );
    },
  },
  mutations: {
    startLoading(state) {
      state.loadingRecipes = true;
    },
    gotRecipes(state, payload: Recipe[]) {
      state.recipes = payload;
      state.error = undefined;
      state.loadingRecipes = false;
    },
    onError(state, error: string) {
      state.error = error;
      state.recipes = undefined;
      state.loadingRecipes = false;
    },
  },
};
