








import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
/**
 * Text field component that calculates the sum of the entered money amounts
 *
 * Can handle . and , as comma separator equally
 * Can also be used to handle full integer addition, e.g. 100+212 = 3.12€
 *   This will be triggered if there are no , and no . and the sum is greater 99
 */
@Component
export default class MoneySumTextField extends Vue {
  @Prop({ default: '' })
  initialText!: string;
  textInput: string = '';
  error: boolean = false;

  mounted() {
    this.textInput = this.initialText;
  }

  // TODO add text and sum as properties to get the first values from the screen

  addbits(s: string) {
    var total = 0;
    const t = s.replace(/\s/g, '').match(/[+\\-]?([0-9\\.\s]+)/g) || [];
    while (t.length) total += parseFloat(t.shift() || '0');
    return total;
  }

  @Watch('textInput')
  onTextInputChanged(text: string) {
    // allow german decimal notation as well
    text = text.replace(/,/g, '.');

    if (!/^([0-9+-. ]*)$/.test(text)) {
      this.error = true;
      return;
    }
    this.error = false;

    // TODO check than only numbers , . + - are in the string
    let sum = this.addbits(text);

    // *100 notation
    if (!text.includes('.') && sum > 99) {
      sum = sum / 100.0;
    }
    this.$emit('textChange', text);
    this.$emit('sumChange', sum);
  }
}
