import { Module } from 'vuex';
import { SplitwiseState, RootState } from './types';
import { api } from '@/api';
import { Session, EbonEntry } from '@yurop-alfred/alfred-api';

function buildDetails(items: EbonEntry[], additional: string): string {
  let result = '';
  for (const item of items) {
    result +=
      item.name +
      (item.amount !== 1 ? ' ' + item.amount : '') +
      (item.unit ? ' ' + item.unit : '') +
      ' ' +
      item.sub_total +
      ' €\n';
  }
  return result + additional;
}

const initialState = {
  error: undefined,
  loading: false,
  selectedReceiptIds: [],
  receipts: [],
  sessions: [],
  selectedSessions: [],
  sessionsHaveBeenSelected: false,
  sessionItems: {},
  extra: {
    items: [],
    name: '',
    sum: 0,
    text: '',
  },
  successfullyInserted: false,
};

export const splitwise: Module<SplitwiseState, RootState> = {
  namespaced: true,
  state: initialState,
  actions: {
    selectReceipts({ commit, rootState }, receiptIds: number[]) {
      commit('setReceiptIds', receiptIds);
      for (const id of receiptIds) {
        api(rootState)
          .rewe.reweReceiptIdGet(id + '')
          .then(
            (response) => commit('addReceipt', response.data),
            (error) => commit('onError', error)
          );
      }
    },
    fetchSessions({ commit, rootState }) {
      commit('startLoading');
      api(rootState)
        .sessions.sessionsLastGet()
        .then(
          (response) => commit('gotSessions', response.data),
          (error) => commit('onError', error)
        );
    },
    selectSessions({ commit, rootState }, sessions: Session[]) {
      commit('setSelectedSessions', sessions);
    },
    insertPurchases({ commit, rootState, state }) {
      commit('startLoading');
      //api(rootState).splitwise.splitwisePost();

      const promises: Promise<any>[] = [];

      for (const session of state.sessions as any) {
        // TODO fix type of sessionState
        if (session.additional > 0 || session.items.length > 0) {
          let sum = session.additional;
          for (const item of session.items) {
            sum += item.sub_total;
          }
          promises.push(
            api(rootState)
              .splitwise.splitwisePost({
                description: session.selectedMealName,
                cost: sum,
                session: session.id,
                details: buildDetails(session.items, session.additionalText),
              })
              .catch((error) => commit('onError', error))
          );
        }
      }

      if (state.extra.sum > 0 || state.extra.items.length > 0) {
        let sum = state.extra.sum;
        for (const item of state.extra.items) {
          sum += item.sub_total;
        }
        promises.push(
          api(rootState)
            .splitwise.splitwiseCommonPost({
              description: state.extra.name,
              category: 12,
              cost: sum,
              details: buildDetails(state.extra.items, state.extra.text),
            })
            .catch((error) => commit('onError', error))
        );
      }
      Promise.all(promises).then(() => {
        const promises = [];

        for (const receipt of state.receipts) {
          promises.push(
            api(rootState)
              .rewe.reweReceiptIdPut(receipt.id + '', { assigned: true })
              .catch((error) => commit('onError', error))
          );
        }
        Promise.all(promises).then(() => {
          commit('finishedInserting');
        });
      });
    },
  },
  mutations: {
    setReceiptIds(state, payload) {
      // reset the store to initial state
      state.error = undefined;
      state.loading = false;
      state.receipts = [];
      state.sessions = [];
      state.selectedSessions = [];
      state.sessionsHaveBeenSelected = false;
      state.sessionItems = {};
      state.extra.items = [];
      state.extra.name = '';
      state.extra.sum = 0;
      state.extra.text = '';
      state.successfullyInserted = false;

      state.selectedReceiptIds = payload;
    },
    addReceipt(state, payload) {
      state.receipts.push(payload);
    },
    gotSessions(state, payload) {
      for (const session of payload) {
        session.items = [];
        session.additional = 0;
        session.additionalText = '';
      }
      state.sessions = payload;
      state.loading = false;
    },
    setSelectedSessions(state, payload) {
      state.selectedSessions = payload;
      state.sessionsHaveBeenSelected = true;
    },
    finishedInserting(state) {
      state.successfullyInserted = true;
      // Also reset
      state.sessionsHaveBeenSelected = false;
    },
    startLoading(state) {
      state.loading = true;
    },
    onError(state, error: string) {
      state.loading = false;
      state.error = error;
    },
  },
};
