import { Module } from 'vuex';
import { RootState, RecipeState } from './types';
import { Recipe, Preference, RecipeParameter } from '@yurop-alfred/alfred-api';
import { api } from '@/api';

export const recipe: Module<RecipeState, RootState> = {
  namespaced: true,
  state: {
    error: undefined,
    loadingRecipe: false,
    recipe: undefined,
    preference: undefined,
    addingToKeep: false,
    updatingRecipe: false,
  },
  actions: {
    fetchRecipe({ commit, rootState }, id: number) {
      commit('startLoading', id);
      api(rootState)
        .recipes.recipesRecipeIdGet(id + '')
        .then(
          (response) => {
            commit('gotRecipe', response.data);
            api(rootState)
              .recipes.recipesRecipeIdPreferenceGet(id + '')
              .then(
                (response) => {
                  commit('gotPreference', response.data);
                },
                (error) => commit('onError', error)
              );
          },
          (error) => commit('onError', error)
        );
    },
    changePreference(
      { commit, rootState },
      { id, preference }: { id: number; preference: string }
    ) {
      api(rootState)
        .recipes.recipesRecipeIdPreferencePut(id + '', {
          preference: preference,
        })
        .then(
          (response) => commit('gotPreference', { preference }),
          (error) => commit('onError', error)
        );
    },
    updateRecipe({ commit, rootState }, recipe: Recipe) {
      commit('setUpdatingRecipe', true);
      const id = recipe.id;
      api(rootState)
        .recipes.recipesRecipeIdPut(id + '', recipe)
        .then((response) => {
          commit('gotRecipe', response.data);
          commit('setUpdatingRecipe', false);
        })
        .catch((error) => console.log(error.response));
    },
    addToKeep({ commit, rootState }, param: RecipeParameter) {
      commit('setAddingToKeep', true);
      api(rootState)
        .keep.keepRecipePost(param)
        .then((_) => commit('setAddingToKeep', false))
        .catch((error) => console.log(error.response));
    },
  },
  mutations: {
    startLoading(state, id: number) {
      state.loadingRecipe = true;
      if (state.recipe && state.recipe.id !== id) {
        state.recipe = undefined;
      }
      state.error = undefined;
    },
    gotRecipe(state, payload: Recipe) {
      state.recipe = payload;
      state.error = undefined;
    },
    gotPreference(state, payload: Preference) {
      state.preference = payload.preference;
      state.loadingRecipe = false;
    },
    onError(state, error: string) {
      state.error = error;
      state.recipe = undefined;
      state.loadingRecipe = false;
    },
    setAddingToKeep(state, adding: boolean) {
      state.addingToKeep = adding;
    },
    setUpdatingRecipe(state, updating: boolean) {
      state.updatingRecipe = updating;
    },
  },
};
