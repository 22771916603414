import { Module } from 'vuex';
import { RootState } from './types';
import { api } from '@/api';
import { Default } from '@yurop-alfred/alfred-api';
import Vue from 'vue';

export interface DefaultsState {
  loading: boolean;
  error?: string;
  defaults: number[];
}

export const defaults: Module<DefaultsState, RootState> = {
  namespaced: true,
  state: {
    loading: false,
    error: undefined,
    defaults: [],
  },

  actions: {
    fetchDefaults({ commit, rootState }) {
      commit('startLoading');
      api(rootState)
        .sessions.sessionsDefaultsGet()
        .then(
          (response) => commit('gotDefaults', response.data),
          (error) => commit('onError', error)
        );
    },
    changeDefault(
      { commit, rootState },
      { weekday, participating }: { weekday: number; participating: number }
    ) {
      if (participating === -1) {
        api(rootState)
          .sessions.sessionsDefaultsDelete({ weekday })
          .then(
            (response) => {
              commit('setDefault', { weekday, participating });
            },
            (error) => commit('onError', error)
          );
      } else {
        api(rootState)
          .sessions.sessionsDefaultsPut({
            weekday,
            participating,
          })
          .then(
            (response) => {
              commit('setDefault', response.data);
            },
            (error) => commit('onError', error)
          );
      }
    },
  },

  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    gotDefaults(state, defaults: Default[]) {
      state.loading = false;
      const participating = [-1, -1, -1, -1, -1, -1, -1];

      for (const def of defaults) {
        participating[def.weekday] = def.participating;
      }

      state.defaults = participating;
    },
    setDefault(state, { weekday, participating }) {
      Vue.set(state.defaults, weekday, participating);
    },
    onError(state, error: string) {
      state.loading = false;
      state.error = error;
    },
  },
};
