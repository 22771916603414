



















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import { RecipeState } from '../store/types';
import { Ingredient } from '@yurop-alfred/alfred-api';

const recipeNs = namespace('recipe');

@Component({})
export default class Recipe extends Vue {
  @State('recipe')
  recipeState!: RecipeState;
  @recipeNs.Action('fetchRecipe')
  fetchRecipe: any;
  @recipeNs.Action('changePreference')
  changePreference: any;
  @recipeNs.Action('addToKeep')
  addToKeep: any;

  portions: number = 0;
  showKeepButton = config.USE_KEEP;

  @Watch('portions')
  onPortionsChanged() {
    if (this.portions !== this.getPortionsFromHash()) {
      this.$router.replace({
        name: 'recipe',
        params: { id: this.$route.params.id },
        hash: '#' + this.portions,
      });
    }
  }

  get scaling() {
    if (!this.recipeState.recipe) return 1;
    return this.portions / this.recipeState.recipe.portions;
  }

  mounted() {
    // TODO detect manual change of hash in url while on page?
    if (this.$route.hash) {
      this.portions = parseInt(this.$route.hash.substring(1));
    }
    this.fetchRecipe(this.$route.params.id);
  }

  getPortionsFromHash() {
    if (this.$route.hash) {
      return parseInt(this.$route.hash.substring(1));
    }
    return 0;
  }

  @Watch('recipeState.recipe')
  onRecipeChange(recipe: Recipe | undefined) {
    if (this.portions === 0 && recipe) {
      this.portions = recipe.portions;
    }
  }

  buildIngredientAmount(ingredient: Ingredient) {
    let result = '';
    if (ingredient.amount && ingredient.amount != 0) {
      result += Number(
        ingredient.amount * this.scaling
      ).toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    if (ingredient.amount != 0 && ingredient.unit != '') {
      result += ' ';
    }
    result += ingredient.unit;
    return result;
  }

  buildIngredientName(ingredient: Ingredient) {
    let result = ingredient.name;
    if (
      ingredient.info &&
      ingredient.info != '' &&
      ingredient.info[0] !== ' ' &&
      ingredient.info[0] !== ','
    ) {
      result += ' ';
    }
    result += ingredient.info || '';
    return result;
  }

  decreasePortions() {
    this.portions--;
    if (this.portions == 0) {
      this.portions = 1;
    }
  }

  increasePortions() {
    this.portions++;
  }

  setPreference(preference: string) {
    this.changePreference({
      id: this.$route.params.id,
      preference: preference,
    });
  }

  addRecipeToKeep() {
    if (confirm('Willst du die Zutaten zur Einkaufsliste hinzufügen?')) {
      this.addToKeep({
        id: parseInt(this.$route.params.id),
        portions: this.portions,
      });
    }
  }
}
