











































































































import { Component, Vue } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import { RecipeState } from '../store/types';
import { Ingredient } from '@yurop-alfred/alfred-api';
import LoadingRing from '../components/LoadingRing.vue';
const recipeNs = namespace('recipe');
@Component({
  components: {
    LoadingRing,
  },
})
export default class EditRecipe extends Vue {
  @State('recipe')
  state!: RecipeState;
  @recipeNs.Action('fetchRecipe')
  fetchRecipe: any;
  @recipeNs.Action('updateRecipe')
  updateRecipe: any;

  mounted() {
    this.fetchRecipe(this.$route.params.id);
  }
  addIngredient() {
    if (this.state.recipe && this.state.recipe.ingredients) {
      this.state.recipe.ingredients.push({
        amount: 0,
        unit: '',
        name: '',
        info: '',
      });
    }
  }

  saveRecipe() {
    this.removeEmptyIngredients();
    this.updateRecipe(this.state.recipe);
  }

  removeEmptyIngredients() {
    if (this.state && this.state.recipe && this.state.recipe.ingredients) {
      for (let i = this.state.recipe.ingredients.length - 1; i >= 0; i--) {
        if (this.isEmptyIngredient(this.state.recipe.ingredients[i])) {
          console.log('empty ' + i);
          this.state.recipe.ingredients.splice(i, 1);
        }
      }
    }
  }

  isEmptyIngredient(ingredient: Ingredient) {
    console.log(ingredient.amount);
    return (
      ingredient.unit === '' &&
      ingredient.info === '' &&
      ingredient.amount == 0 &&
      ingredient.name === ''
    );
  }
}
