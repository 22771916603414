















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { AuthState } from '@/store/types';
import axios from 'axios';
@Component({})
export default class Login extends Vue {
  @State('auth') auth!: AuthState;
  @Action('loginWithToken', { namespace: 'auth' }) loginWithToken: any;
  loggingIn: boolean = false;

  showTestLogin = config.DEBUG;

  @Watch('auth.authenticated')
  onAuthenticatedChange(authenticated: boolean) {
    this.loggingIn = false;
    if (authenticated) {
      this.$router.replace({ name: 'recipes' });
    }
  }

  @Watch('auth.loginError')
  onLoginErrorChange(value: string) {
    this.loggingIn = false;
  }

  mounted() {
    if (this.$route.query.token) {
      this.loggingIn = true;
      this.loginWithToken(this.$route.query.token);
    } else {
      this.legacyLogin();
    }
  }

  legacyLogin() {
    var name = 'hash=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        this.loginWithToken(c.substring(name.length, c.length));
      }
    }
  }
}
