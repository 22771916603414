import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { auth } from './auth';
import { recipeList } from './recipes';
import { recipe } from './recipe';
import { nomonomicon } from './nomonomicon';
import { ebon } from './ebon';
import { splitwise } from './splitwise';
import { defaults } from './defaults';
Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    recipeList,
    recipe,
    nomonomicon,
    ebon,
    splitwise,
    defaults,
  },
};

export default new Vuex.Store<RootState>(store);
