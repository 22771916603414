

















import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';

@Component({})
export default class AddRecipe extends Vue {
  recipeName: string = '';
  chefkochLink: string = '';
  mounted() {}

  addRecipe() {
    if (this.recipeName !== '') {
      api(this.$store.state)
        .recipes.recipesPost({ name: this.recipeName })
        .then(
          (response) =>
            this.$router.replace({
              name: 'editRecipe',
              params: {
                id: response.data.id + '',
              },
            }),
          (error) => console.error(error)
        );
    }
  }

  importRecipe() {
    if (this.isValidChefkochLink()) {
      api(this.$store.state)
        .recipes.recipesImportPost({ link: this.chefkochLink })
        .then(
          (response) =>
            this.$router.replace({
              name: 'editRecipe',
              params: {
                id: response.data.id + '',
              },
            }),
          (error) => console.error(error)
        );
    }
  }

  isValidChefkochLink() {
    return this.chefkochLink.startsWith('https://www.chefkoch.de/rezepte/');
  }
}
