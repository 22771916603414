import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Plan from '../views/Plan.vue';
import Splitwise from '../views/Splitwise.vue';
import Login from '@/views/Login.vue';
import RecipeList from '@/views/RecipeList.vue';
import Recipe from '@/views/Recipe.vue';
import AddRecipe from '@/views/AddRecipe.vue';
import EditRecipe from '@/views/EditRecipe.vue';
import User from '@/views/User.vue';
import NotFound from '@/views/NotFound.vue';
import Nomonomicon from '@/views/Nomonomicon.vue';
import SuggestRecipe from '@/views/SuggestRecipe.vue';
import store from '@/store';
import { auth } from '@/store/auth';
import { AuthState } from '@/store/types';
import { api } from '@/api';
import { UsersApi } from '@yurop-alfred/alfred-api';
import EbonsList from '@/views/EbonsList.vue';
import Defaults from '@/views/Defaults.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/recipes/login', // TODO needs to be in /recipes for now for legacy login using cookies
    name: 'login',
    component: Login,
    meta: {
      public: true,
    },
  },

  { path: '/', redirect: '/recipes' },
  {
    path: '/recipes',
    name: 'recipes',
    component: RecipeList,
  },
  {
    path: '/recipes/add',
    name: 'addRecipe',
    component: AddRecipe,
  },
  {
    path: '/recipes/:id',
    name: 'recipe',
    component: Recipe,
  },
  {
    path: '/recipes/:id/edit',
    name: 'editRecipe',
    component: EditRecipe,
  },
  {
    path: '/user',
    name: 'user',
    component: User,
  },

  {
    path: '/nomonomicon',
    name: 'nomonomicon',
    component: Nomonomicon,
  },
  {
    path: '/nomonomicon/suggest',
    name: 'suggestRecipe',
    component: SuggestRecipe,
  },
  {
    path: '/user/defaults',
    name: 'defaults',
    component: Defaults,
  },
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

// load additional routes if modules are active
if (config.USE_REWE) {
  routes.push({
    path: '/receipts',
    name: 'receipts',
    component: EbonsList,
  });
}

if (config.USE_SPLITWISE) {
  routes.push({
    path: '/splitwise',
    name: 'splitwise',
    component: Splitwise,
  });
}

// TODO plan module
/*
  {
    path: '/',
    name: 'home',
    component: Plan
  },
*/

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.public)) {
    // non-public pages require login
    if ((auth.state as AuthState).authenticated) {
      next();
    } else {
      const token = (auth.state as AuthState).token;
      if (token !== undefined) {
        // test whether token is still valid
        new UsersApi({ accessToken: token, basePath: config.API_URL })
          .usersMeGet()
          .then(
            (response) => {
              const user = response.data;
              store.commit('auth/successfulLogin', user);
              next();
            },
            (_) => {
              // require login
              next({
                name: 'login',
              });
            }
          );
      } else {
        // require login
        next({
          name: 'login',
        });
      }
    }
  } else {
    next();
  }
});

export default router;
