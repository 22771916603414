






























import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AuthState } from '../store/types';
@Component({})
export default class Header extends Vue {
  @State('auth') auth!: AuthState;
  getAvatar() {
    if (this.auth.authenticated && this.auth.user && this.auth.user.avatar) {
      return this.auth.user.avatar;
    }
    return require('@/assets/account.png');
  }
}
