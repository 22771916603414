





























































import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace, State } from 'vuex-class';
import { NomonomiconState } from '../store/types';

const nomNamespace = namespace('nomonomicon');
@Component({})
export default class Nomonomicon extends Vue {
  @State('nomonomicon')
  state!: NomonomiconState;
  @State((state) => state.auth.user.id)
  userId!: any;
  @nomNamespace.Action('fetchNomonomicon')
  fetchNomonomicon: any;
  @nomNamespace.Action('changePreference')
  changePreference: any;

  editingPreference: number = 0;

  selectedUsers: number[] = [];

  mounted() {
    this.fetchNomonomicon();
  }

  editPreference(recipeId: number, target: any) {
    this.editingPreference = recipeId;

    var dialog = document.getElementById('preferenceDialog')!;
    var bodyRect = document.body.getBoundingClientRect(),
      rect = target.getBoundingClientRect();

    dialog.style.top = rect.top - bodyRect.top + 20 + 'px';

    var leftOffset =
      rect.left - dialog.offsetWidth / 2 + target.offsetWidth / 2;
    if (leftOffset + dialog.offsetWidth >= document.body.clientWidth) {
      dialog.style.right = '0px';
      dialog.style.left = '';
    } else {
      dialog.style.left = leftOffset + 'px';
      dialog.style.right = '';
    }
  }

  setPreference(preference: string) {
    this.changePreference({ id: this.editingPreference, preference });
    this.editingPreference = 0;
  }

  toggleSelectUser(userId: number) {
    if (this.selectedUsers.includes(userId)) {
      this.selectedUsers.splice(this.selectedUsers.indexOf(userId), 1);
    } else {
      this.selectedUsers.push(userId);
    }
  }

  showSuggestions() {
    if (this.selectedUsers.length > 0) {
      this.$router.push({
        name: 'suggestRecipe',
        query: {
          participants: this.selectedUsers.join(','),
        },
      });
    }
  }
}
