import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e0c621466ced4227adc9a993d64ffdff@sentry.io/2827593',
    integrations: [new Integrations.Vue({ attachProps: true })],
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
