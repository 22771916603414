import { Module } from 'vuex';
import { RootState, NomonomiconState } from './types';
import {
  Recipe,
  Preference,
  User,
  RecipeSuggestion,
} from '@yurop-alfred/alfred-api';
import { api } from '@/api';

export const nomonomicon: Module<NomonomiconState, RootState> = {
  namespaced: true,
  state: {
    users: undefined,
    recipes: undefined,
    preferences: undefined,
    suggestions: undefined,
    finishedLoading: false,
    error: undefined,
  },
  actions: {
    fetchNomonomicon({ commit, rootState }) {
      commit('startLoading');

      api(rootState)
        .users.usersGet()
        .then(
          (response) => commit('gotUsers', response.data),
          (error) => commit('onError', error)
        );
      api(rootState)
        .recipes.recipesGet()
        .then(
          (response) => commit('gotRecipes', response.data),
          (error) => commit('onError', error)
        );
      api(rootState)
        .recipes.recipesPreferencesGet()
        .then(
          (response) => commit('gotPreferences', response.data),
          (error) => commit('onError', error)
        );
    },
    changePreference(
      { commit, rootState },
      { id, preference }: { id: number; preference: string }
    ) {
      api(rootState)
        .recipes.recipesRecipeIdPreferencePut(id + '', {
          preference: preference,
        })
        .then(
          (response) => commit('gotPreference', response.data),
          (error) => commit('onError', error)
        );
    },

    fetchSuggestion({ commit, rootState }, participants: number[]) {
      console.log(api(rootState).recipes);
      commit('startLoading');
      api(rootState)
        .recipes.recipesSuggestPost({ participants: participants })
        .then(
          (response) => commit('gotSuggestions', response.data),
          (error) => commit('onError', error)
        );
    },
  },
  mutations: {
    startLoading(state) {
      state.error = undefined;
      state.finishedLoading = false;
    },
    gotUsers(state, users: User[]) {
      state.users = users;
      determineFinishedLoading(state);
    },
    gotRecipes(state, recipes: Recipe[]) {
      state.recipes = recipes.sort((a, b) => a.name.localeCompare(b.name));
      determineFinishedLoading(state);
    },
    gotPreferences(state, preferences: Preference[]) {
      state.preferences = {};
      // map preferences by user and recipe
      for (const preference of preferences) {
        state.preferences[preference.userId + ',' + preference.mealId] =
          preference.preference;
      }

      determineFinishedLoading(state);
    },
    gotPreference(state, preference: Preference) {
      /*state.preferences![preference.userId + ',' + preference.mealId] =
        preference.preference;
      console.log(state.preferences);*/
      // Use this to actually trigger a rebuild
      state.preferences = Object.assign({}, state.preferences, {
        [preference.userId + ',' + preference.mealId]: preference.preference,
      });
    },

    gotSuggestions(state, suggestions: RecipeSuggestion[]) {
      state.suggestions = suggestions;
    },
    onError(state, error: string) {
      state.error = error;
    },
  },
};

function determineFinishedLoading(state: NomonomiconState) {
  state.finishedLoading =
    state.users !== undefined &&
    state.recipes !== undefined &&
    state.preferences !== undefined;
}
